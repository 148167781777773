.wordDoc { padding:40px;
  //added by Linus, used in external resource page
    &.generalTips img { max-width:50px; margin:0 auto 20px; display:inline; margin-right: 40px}; 
            span {font-size:18px; margin:0 0 10px 0px; color:$corporateRedColor}
    
    &.userGuide img { max-height:35px;width:auto; margin:0 auto 20px; display:inline-block; }; 
        span {font-size:18px; margin:0 0 10px 0px; color:black}


  h2 { color:$corporateRedColor; font-size:30px; margin:0 0 10px 0; display:inline-block}
  
  // page title, good
  h3 { color:$corporateRedColor; font-size:28px; margin:0 0 20px 0; padding:0 0 10px 0; display:inline-block; border-bottom: dotted 2px #000; }
  
  // Good
  h4 { color:$corporateRedColor; font-size:18px; margin:0 0 10px 0; }


  h5 { color:$majorDarkGrey; font-size:13px; margin:0 0 20px 0; }
  
  ul { list-style:none; position:relative; margin:0 0 0 -10px; font-size:17px;
      li::before {content: ''; display: inline-block; width:6px; height:6px;border-radius:5px; background-color:$corporateRedColor; position:absolute; top:8px; left:-20px; text-align:center; vertical-align:middle;
      }
      li { padding-bottom:20px; position:relative;}
  }

    ol { position:relative; margin:0 0 0 -10px; font-size:17px;
    //   li::before {content: ''; display: inline-block; width:6px; height:6px;border-radius:5px; background-color:$corporateRedColor; position:absolute; top:8px; left:-20px; text-align:center; vertical-align:middle;
    //   }
      li { padding-bottom:20px; position:relative;}
  }

  
  p { font-size:17px; margin:0 0 10px 0; 
      a { font-size:17px; }
  }

  strong {font-size:17px; margin:0 0 10px 0;

  }

  b {font-size:17px; margin:0 0 10px 0;

  }

  img { width:100%; max-width:600px; margin:0 auto 20px; display:block; }

  table {
      tr {
          td { padding:30px;}
      }
  }

  table.border { border:solid 1px #000; margin:0 auto; 
      tr {
          td { padding:20px; vertical-align:middle; text-align:center; 
              &:first-child { text-align:left; }
          }
      }
  }

  video { max-width:100%; }
}

@media (min-width: $screen-sm-min) {
    .wordDoc {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
