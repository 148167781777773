// ROOT
#root { 
	>div { overflow:hidden; z-index:0; }
}

// WRAP
#wrap {
	position: relative;
	padding-top: $headerDesktopHeight;
	left: 0;
	background: url('../images/bg_content.png') center top repeat;
	z-index: 1;
	@include transition(0.1s);
	height: 100%;
	&.ch { font-family:$font-family-body-chinese !important; 
		h1, 
		h2, 
		h3, 
		h4, 
		h5, 
		h6 { font-family:$font-family-body-chinese !important; }
	}
}

// HEADER
.wrapper-header {
	position: fixed;
	top: 0;
	width: 100%;
	height: $headerDesktopHeight;
	z-index: 1000;
	background: #f8f9fa;
	left: 0;
	@include transition(0.1s);
	border-bottom: solid 5px $corporateRedColor;

	.header {
		width: 100%;
		max-width: 1200px;
		height: $headerDesktopHeight;
		margin: 0 auto;
		padding: 0 20px;
		position: relative;
		display: flex;
		flex-wrap: wrap;

		.logo-NA-top {
			width: 50px;
			position: relative;
			display: block;
			margin: 9px 0 0 0;
			padding: 0;

			a {
				display: block;

				img {
					width: 100%;
				}

				.mobile-top-logo {
					max-height: 50px;
				}
			}
		}
	
		.logo-schk {
			display: block;
			margin-top: 10px;
			padding: 0;
			flex: auto;

			a {
				display: block;
				margin-left: 0.25rem;

				.mobile-schk-logo {
					max-height: 50px;
				}

				img {
					display: inline-block;
					max-width: 50px;
				}
			}
		}

		.desktop-language {
			list-style: none;
			display: block;
			margin: 25px 5px;
			padding: 0;
			>li { display:block; float:left; border-left:solid 1px #000; 
				&:first-child { border:none; }

				a {
					display: block;
					line-height: 1em;
					padding: 0 0.6rem;
					font-size: 20px;
					color: #DEDDDF;
					font-size: 20px;

					&:hover,
					&.active { color:$aColor; }
				}
			}
		}

		.helloUser {
			margin: 10px 15px 0;
			padding: 0;
			display: block;
			span {
				display: block;
				text-align: right;
				color: #000;
			}
		}

		.desktop-login,
		.desktop-logout {
			width: 90px;
			height: 31px;
			margin-top: 20px;
			padding: 7px 9px 5px 40px;
			display: block;
			color: #fff;
			text-align: center;
			font-size: 13px;
			background: url('../images/btn_header_Login.png') center top / 100% no-repeat;
			&:hover {
				background-position: center bottom;
			}
		}
	}
}

// FOOTER
.wrapper-footer {
	background: #fff;
	border-top: thick solid $corporateRedColor;
	padding: 1rem 0;
	text-align: center;

	.footer {
		max-width: 1200px;
		margin: auto;
		padding: 10px 0;
	}

	.footer-column {
		padding-left: 20px;
		padding-right: 20px;
		h2 {
			margin-top: 1.5rem;
			margin-bottom: 1.8rem;
		}
		margin-bottom: 2rem;
	}

	.footer-subscription {
		.footer-subscription-logo {
			width: 100%;
		}
		label {
			display: block;
			margin-top: 1rem;
			margin-bottom: 1rem;
			font-size: 13px;
			font-weight: normal;
		}
		input {
			background: $majorDarkGrey; border-color:$majorDarkGrey !important;
			color:#fff;
			&::placeholder {
				color: scale-color($majorLightGrey, $lightness: 66%);
			}
			border-color:$bodyColor;
			border-radius: 0;
		}
		.input-group-btn,
		button {
			cursor: unset;
			background-color: $corporateRedColor;
			color:#fff;
			&:hover {
				background-color: scale-color($corporateRedColor, $lightness: -20%);
				border-color: scale-color($corporateRedColor, $lightness: -20%);
			}
			&:active {
				background-color: scale-color($corporateRedColor, $lightness: -30%);
				border-color: scale-color($corporateRedColor, $lightness: -30%);
				color: #fff;
			}
			border-color: $corporateRedColor;
			border-radius: 0;
		}
		.input-group-btn:last-child > .btn {
			margin-left: 0;
		}
	}

	.footer-contact {
		h2 { padding-left: 36px }
		ul {
			list-style-type: none;
		}
		li {
			line-height: 1.6em;
			margin: auto;
			margin-bottom: 1rem;
			font-size: 13px;
			font-weight: normal;
			max-width: 25rem;
			position: relative;

			a:hover,
			a:focus,
			a:active {
				text-decoration: underline;
			}
		}
		li:before {
			content: '';
			display: block;
			width: 16px;
			height: 16px;
			background: center / cover no-repeat;
			position: absolute;
			top: 50%;
			margin-top: -8px;
		}
		.email:before {
			background-image: url('../images/Footer/ic_footer-contact-Email.png');
		}
		.tel:before {
			background-image: url('../images/Footer/ic_footer-contact-Tel.png');
		}
		.fax:before {
			background-image: url('../images/Footer/ic_footer-contact-Fax.png');
		}
		.footer-social-icon-div a {
			display: inline-block;
			margin: 0.5rem;
		}
		.footer-social-icon {
			width: 48px;
			&:hover,
			&:focus,
			&:active {
				opacity: 0.7;
			}
		}
	}

	.footer-address p {
		font-size: 13px;
	}
}

// SITEMAP
.wrapper-sitemap {

    .sitemap { position:relative; max-width:1640px; margin:0 auto; padding:20px; color:#fff; 

        >ul { float:left; width:50%; }
        
        h3 { font-size:14px; margin:20px 0 10px 0;}
    }
}

// MainMenu
.wrapper-MainMenu { position:relative; width:100%; z-index:100;
	.MainMenu { position:relative; max-width:1200px; margin:0 auto; 
		>ul { list-style:none; display:block; margin:0; padding: 0; 
			>li { display:block; float:left; width: calc(100% / 6); position:relative;
				>a {
					display: table;
					text-align: center;
					width: 100%;
					padding: 21px 0 0;
					color: #fff;
					font-size: 16px;
					line-height: 1.3em;
					height: 90px;

					&.about { background:url('../images/Menu/img_Menu-01About_Us_01frame.jpg') left -15px repeat-x; position:relative; 
						&:before { content:''; background:url('../images/Menu/img_Menu-01About_Us_02line.jpg') left -15px repeat-x; position:absolute; right:-1.8%; top:0; height:100%; width:2.9%; z-index:2;}
					}
					&.resource { background:url('../images/Menu/img_Menu-02Resources_for_you_01frame.jpg') left -15px repeat-x; position:relative; 
						&:before { content:''; background:url('../images/Menu/img_Menu-02Resources_for_you_02line.jpg') left -15px repeat-x; position:absolute; right:-2.3%; top:0; height:100%; width:2.8%; z-index:2; }
					}
					&.featured { background:url('../images/Menu/img_Menu-03Featured_Issues_01frame.jpg') left -15px repeat-x; position:relative; 
						&:before { content:''; background:url('../images/Menu/img_Menu-03Featured_Issues_02line.jpg') left -15px repeat-x; position:absolute; right:-1.8%; top:0; height:100%; width:2.5%; z-index:2;}
					}
					&.research { background:url('../images/Menu/img_Menu-04Research_Finding_01frame.jpg') left -15px repeat-x; position:relative; 
						&:before { content:''; background:url('../images/Menu/img_Menu-04Research_Finding_02line.jpg') left -15px repeat-x; position:absolute; right:-2.3%; top:0; height:100%; width:2.5%; z-index:2;}
					}
					&.event { background:url('../images/Menu/img_Menu-05Our_Event_01frame.jpg') left -15px repeat-x; position:relative;
						&:before { content:''; background:url('../images/Menu/img_Menu-05Our_Event_02line.jpg') left -15px repeat-x; position:absolute; right:-1.8%; top:0; height:100%; width:2.5%; z-index:2;}
					}
					&.external { background:url('../images/Menu/img_Menu-06External_Resources_01frame.jpg') left -15px repeat-x; position:relative; z-index:1; }
					
					span { display:table-cell; height:69px; vertical-align:middle; position:relative; }
				}
				&:hover >a span { color:$corporateRedColor; background:url('../images/Menu/btn_mene_select.png') center center no-repeat; background-size: 100% 100%; }

				ul { list-style:none; display:none; margin:0; padding:0; position:absolute; top:100%; left:50%; margin-left:-197px; width:394px; 

					&.leftOne,
					&.leftTwo {
						margin-left: -76px;
						.top { height:58px; background:url('../images/Menu/bubble_sub_menu-01top_1_left.png') center top no-repeat; }
					}

					&.leftTwo {
						li {

							&:nth-of-type(n+2) {
								border-top: solid 2px $bodyColor;
							}

							a { border:none; display:block; margin:0 0 0 20px; padding:0; text-align:center;
								&:first-child{ margin:0; }

								.thumb {
									display: inline-block;
									width: 100%;
									height: 135px;

									&.one { background:url('../images/Menu/btn_resources_for_you-01.png') center top no-repeat;}
									&.two { background:url('../images/Menu/btn_resources_for_you-02.png') center top no-repeat;}
									&.three { background:url('../images/Menu/btn_resources_for_you-03.png') center top no-repeat;}
								}
								&:hover .thumb { background-position: center bottom; }

								span { padding: 2px 10px; display:inline-block; }
								&:hover span { background: none; }
							}
						}
					}
					
					.top { height:58px; background:url('../images/Menu/bubble_sub_menu-01top_2_center.png') center top no-repeat;}
					.middle { background:url('../images/Menu/bubble_sub_menu-02center.png') center top repeat-y; padding:0 40px; }
					.bottom { height:58px; background:url('../images/Menu/bubble_sub_menu-03bottom.png') center top no-repeat; }

					// variation at homepage start
					// &.atHome { top:auto; bottom:100%; padding-bottom:100%; 
					// 	&.leftOne { 
					// 		.bottom{ height:58px; background:url('../images/Menu/bubble_2-sub_menu-01top_1_left.png') center top no-repeat; }
					// 	}
					// 	&.leftTwo { margin-left:-76px; width:585px;
					// 		.top { height:28px; background:url('../images/Menu/bubble2_sub_menu-resourcesforyou_03_bottom.png') center top no-repeat; }
					// 		.middle { background:url('../images/Menu/bubble2_sub_menu-resourcesforyou_02_center.png') center top repeat-y;padding:0; }
					// 		.bottom { height:58px; background:url('../images/Menu/bubble2_sub_menu-resourcesforyou_01_top.png') center top no-repeat; width:585px;}
					// 	}
					// 	.top { height:28px; background:url('../images/Menu/bubble_2-sub_menu-03bottom.png') center top no-repeat;}
					// 	.middle { background:url('../images/Menu/bubble_sub_menu-02center.png') center top repeat-y; padding:0 40px; }
					// 	.bottom { height:58px; background:url('../images/Menu/bubble_2-sub_menu-01top_2_center.png') center top no-repeat; }
					// }
					// variation at homepage end

					li { display:block; 
						&:first-child > a { border:none; }
						>a { display:block; padding: 15px 0; text-align:center; border-top: solid 2px $bodyColor; font-size:18px; line-height:1.3em; 
							span { padding: 2px 10px; display:inline-block; }
							&:hover span { background:$mainMenuHoverYellow; }
						}
					}
				}

				&:hover.menu_opened > ul { display:block; }
			}
		}
	}
}

// bootstrap main menu
.stc-mainmenubar {
    .stc-mainmenubar-logo {
        max-height: 4rem;
    }

    //TODO: broken the to only use the arrow part and use pure css for the round button effect
    .mainMenuBarLoginButton {
        color: white !important;
        padding-left: 4.1rem !important;
        padding-right: 1rem !important;
        background: center / contain no-repeat url('../images/btn_header_Login_default.png');

        &:hover,
        &:active {
            background-image: url('../images/btn_header_Login_active.png');
        }
    }
}

.stc-common-page {
    padding-top: 5.6rem;
    .stc-common-page-container {
        background: url('../images/bg_content.png');
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: red solid thick;
        .carousel .slide {
            background-color: transparent;
        }
    }
    .stc-common-page-content {
        min-height: 10rem;
        background-color: white;
    }
}

.stc-game-page-content {
    min-height: 10rem;
    background-color: black;
    color: white;
}

.login-popup-form {
    padding: 1rem;
}

.forget-your-password {
    display: block;
    margin-top: 1rem;
}

$aboutUsColor: #153568;
$aboutUsColorLight: #4C689A;
$resourcesForYouColor: #D50100;
$resourcesForYouColorLight: #FF5353;
$featuredIssuesColor: #C45503;
$featuredIssuesColorLight: #E67F00;
$researchFindingsColor: #2D5E2F;
$researchFindingsColorLight: #418643;
$ourEventsColor: #BE9601;
$ourEventsColorLight: #EEA000;
$externalResourcesColor: #008D96;
$externalResourcesColorLight: #41A8AF;

$activeTextColor: #F5182A;
$activeBgColorInner: #FFCB41;
$activeBgColorOuter: #F1F268;

.stc-menubar {
    padding-left: 0;
    padding-right: 0;
    .stc-menubar-navbar-nav {
        .dropdown {
            .dropdown-toggle {
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-top-width: 1rem;
                border-top-style: solid;
				color: white;

				@media (max-width: $screen-sm-min - 1) {
					padding-left: 4rem;
				}

				&:before {
					content: '';
					position: absolute;
					width: 3rem;
					height: 3rem;
					top: 0;
					left: 0;
					background: center / contain no-repeat;
				}
			}
        }

        .about-us-dropdown {
            .dropdown-toggle {
                background-color: $aboutUsColor;
				border-top-color: $aboutUsColorLight;

				&:before {
					background-image: url('../images/HeroMenu/img_homepage_menu_01-About_default.png');
				}
			}

			&.open .dropdown-toggle:before {
				background-image: url('../images/HeroMenu/img_homepage_menu_01-About_active.png');
			}
        }

        .resources-for-you-dropdown {
            .dropdown-toggle {
                background-color: $resourcesForYouColor;
				border-top-color: $resourcesForYouColorLight;

				&:before {
					background-image: url('../images/HeroMenu/img_homepage_menu_02-Resource_default.png');
				}
			}

			&.open .dropdown-toggle:before {
				background-image: url('../images/HeroMenu/img_homepage_menu_02-Resource_active.png');
			}
        }

        .featured-issues-dropdown {
            .dropdown-toggle {
                background-color: $featuredIssuesColor;
				border-top-color: $featuredIssuesColorLight;

				&:before {
					background-image: url('../images/HeroMenu/img_homepage_menu_03-Featured_default.png');
				}
			}

			&.open .dropdown-toggle:before {
				background-image: url('../images/HeroMenu/img_homepage_menu_03-Featured_active.png');
			}
        }

        .research-finding-dropdown {
            .dropdown-toggle {
                background-color: $researchFindingsColor;
				border-top-color: $researchFindingsColorLight;

				&:before {
					background-image: url('../images/HeroMenu/img_homepage_menu_04-Research_default.png');
				}
			}

			&.open .dropdown-toggle:before {
				background-image: url('../images/HeroMenu/img_homepage_menu_04-Research_active.png');
			}
        }

        .our-events-dropdown {
            .dropdown-toggle {
                background-color: $ourEventsColor;
				border-top-color: $ourEventsColorLight;

				&:before {
					background-image: url('../images/HeroMenu/img_homepage_menu_05-Ourevent_default.png');
				}
			}

			&.open .dropdown-toggle:before {
				background-image: url('../images/HeroMenu/img_homepage_menu_05-Ourevent_active.png');
			}
        }

        .external-resources-dropdown {
            .dropdown-toggle {
                background-color: $externalResourcesColor;
				border-top-color: $externalResourcesColorLight;

				&:before {
					background-image: url('../images/HeroMenu/img_homepage_menu_06-External_default.png');
				}
			}

			&.open .dropdown-toggle:before {
				background-image: url('../images/HeroMenu/img_homepage_menu_06-External_active.png');
			}
        }

        .dropdown.open,
        .dropdown.show {
            .dropdown-toggle {
                background-color: $activeBgColorInner;
                background-image: radial-gradient($activeBgColorInner, $activeBgColorOuter);
                color: $activeTextColor;
            }
        }
    }
}

.stc-homemenubar {
    // .about-us-dropdown::before {
    // }
    // .about-us-dropdown.active::before,
    // .about-us-dropdown.show::before {

    // }

    .resources-for-you-dropdown {
        .dropdown-toggle {
            background-color: $resourcesForYouColor;
            border-top-color: $resourcesForYouColorLight;
        }
    }

    .featured-issues-dropdown {
        .dropdown-toggle {
            background-color: $featuredIssuesColor;
            border-top-color: $featuredIssuesColorLight;
        }
    }

    .research-finding-dropdown {
        .dropdown-toggle {
            background-color: $researchFindingsColor;
            border-top-color: $researchFindingsColorLight;
        }
    }

    .our-events-dropdown {
        .dropdown-toggle {
            background-color: $ourEventsColor;
            border-top-color: $ourEventsColorLight;
        }
    }

    .external-resources-dropdown {
        .dropdown-toggle {
            background-color: $externalResourcesColor;
            border-top-color: $externalResourcesColorLight;
        }
    }

    .dropdown.active,
    .dropdown.show {
        .dropdown-toggle {
            background-color: $activeBgColorInner;
            background-image: radial-gradient($activeBgColorInner, $activeBgColorOuter);
            color: $activeTextColor;
        }
    }
}

// CONTAINER
.wrapper-container-main {
	position: relative;
	width: 100%;
	background-size: 100%;

	.container-main {
		position: relative;
		max-width: 1200px;
		margin: 0 auto;

		.Breadcrumb {
			color: #000;
			margin: 0;
			padding: 10px 20px;
			border-radius: 0;

			span {
				display: inline-block;
				font-size: 14px;
				padding: 5px;

				&:first-child {
					padding-left: 0;
				}

				a {
					display: block;
					color: #000;
					font-size: 14px;
				}
			}
		}

		.wrapper-content {
			position: relative;
			padding: 20px;

			.content {
				padding: 0;
				max-width: 1020px;
				margin: 0 auto;

				&.lavender-background {
					background-color: $bgLavender;

					h2 {
						color: $corporateRedColor;
						font-size: 30px;
						padding: 30px;
						background-color: $bodyBgColor
					}

					img {
						max-width: 100%;
					}

					.bottom {
						padding: 0 0 0 0;
					}
				}
			}
		}
	}
}

@media (min-width: 370px) {
	.wrapper-header {
		.header {
			.logo-schk {
				a {
					margin-left: 2rem;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	#wrap {
		padding-top: $headerDesktopHeight;
	}

	.wrapper-header {
		height: $headerDesktopHeight;

		.header {
			height: $headerDesktopHeight;

			.logo-NA-top {
				width: 150px;
			}

			.logo-schk {
				a {
					margin-left: 0;
					text-align: right;

					img {
						max-width: 200px;
					}
				}
			}

			.desktop-language {
				margin-left: 1rem;
				margin-right: 1rem;

				>li {
					a {
						padding-left: 15px;
						padding-right: 15px;
					}
				}
			}

			.helloUser {
				margin-top: 20px;
				margin-bottom: 20px;
			}

			.desktop-login,
			.desktop-logout {
				width: 140px;
				height: 49px;
				margin-top: 11px;
				padding: 15px 20px 15px 70px;
				font-size: 16px;
			}
		}
	}

	.wrapper-MainMenu {
		.MainMenu {
			>ul {
				>li {
					>a {
						font-size: 20px;
					}
					ul {
						&.leftTwo {
							width: 585px;

							.top {
								height: 54px;
								background: url('../images/Menu/bubble_sub_menu-resourcesforyou_01_top.png') center top no-repeat;
							}

							.middle {
								background: url('../images/Menu/bubble_sub_menu-resourcesforyou_02_center.png') center top repeat-y;
								padding: 0;
							}

							.bottom {
								height:28px;
								background: url('../images/Menu/bubble_sub_menu-resourcesforyou_03_bottom.png') center top no-repeat;
							}

							li {
								float: left;
								width: 33.33%;
								padding: 0px;

								&:nth-of-type(n+2) {
									border-top: none;
									border-left: solid 2px $bodyColor;
								}

								a {
									.thumb {
										width: 142px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.stc-menubar {
		.stc-menubar-navbar-nav {
			width: 100%;
			display: flex;

			.dropdown {
				text-align: center;
				flex-basis: 100%;

				.dropdown-toggle {
					height: 100%;

					&:before {
						content: none;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.wrapper-header {
		.header {
			.logo-schk {
				a {
					img {
						max-width: 250px;
					}
				}
			}
		}
	}
}
