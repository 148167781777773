// Typography
$font-family-body: 'Palanquin Dark', sans-serif;
$font-family-titles: 'Mitr', sans-serif;

$font-family-body-chinese: 'Microsoft JhengHei', sans-serif;
$font-family-titles-chinese: 'Microsoft JhengHei', sans-serif;

$global-font-size: 15px;
$global-line-height: 1.2em;

$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;


// Header
$headerDesktopHeight: 75px;


// Body
$bodyColor:#000;
$bodyBgColor: #fff;
$corporateRedColor:#da291c;
$majorLightGrey:#e0e0e0;
$majorDarkGrey:#717171;

// Hyperlink
$aColor:#0a95ea;
$aHoverColor:#0a95ea;

// Menu
$mainMenuHoverYellow: #fffeaf;
// #153568 #4b689a
// #d40000 #fe5353
// #c45502 #e67e00
// #2d5e30 #428644
// #be9500 #efa007
// #018d96 #42a8b0

// Page background colors
$bgGreenYellow: #C1FE01; 
$bgGreenBlue: #00E2E1;
$bgBlueGreen: #42A9B0;
$bgLavender: #E6E6FA;

// Text box background colors
$textBoxBg:rgba(128,128,128,0.2);     
$textBoxBgDark:rgba(128,128,128,0.6);

// Quiz
$majorBlue:#0a95ea;
$majorDarkBlue:#00008B;

// SLICK
$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: white !default;
$slick-dot-color-active: grey !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 16px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;