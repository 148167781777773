.events {
    ul.nav-tabs{ margin: 0 0 30px 0; border:none;
        li { width:50%; border:none; border-radius:0; padding: 0 0 0 10px;
            &:first-child { padding: 0 10px 0 0; }
            &.active { border:none; }
            
            a { border:none; border-radius:0; background:$corporateRedColor; color:#fff; font-size:30px; display:block; padding:15px; line-height:1em;
                &:hover { border:none; }
                &:focus { outline:none; border:none; }
            }
            
            &.active { border:none; border-radius:0;
                a { background:$corporateRedColor; color:#fff; font-size:30px; 
                    &:hover { border:none; }
                }
            }
        }
    }
}