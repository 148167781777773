.contact { background:#fff;
    .narrow{ max-width:440px; margin:0 auto; }

    p { padding:0 0 0 60px; margin:20px 0 0 0;position:relative; 
        &:first-child { margin:0; }

        &:before { content:''; width:30px; height:30px; position:absolute; top:-3px; left:0; }

        &.facebook { 
            &:before {
                background: url('../images/AboutUs/ContactUs/ic_contactus-01FB.png') left top no-repeat; background-size:100%; 
            } 
            a:hover,
			a:focus,
			a:active {
				text-decoration: underline;
            }
        }

        &.instagram { 
            &:before {
                background: url('../images/AboutUs/ContactUs/ic_contactus-02IG.png') left top no-repeat; background-size:100%; 
            } 
            a:hover,
			a:focus,
			a:active {
				text-decoration: underline;
            }
        }

        &.tel { 
            &:before {
                background: url('../images/AboutUs/ContactUs/ic_contactus-03Tel.png') left top no-repeat; background-size:100%; 
            } 
            a:hover,
			a:focus,
			a:active {
				text-decoration: underline;
            }
        }

        &.fax { 
            &:before {
                background: url('../images/AboutUs/ContactUs/ic_contactus-04Fax.png') left top no-repeat; background-size:100%; 
            } 
            a:hover,
			a:focus,
			a:active {
				text-decoration: underline;
            }
        }

        &.email { 
            &:before {
                background: url('../images/AboutUs/ContactUs/ic_contactus-05Email.png') left top no-repeat; background-size:100%; 
            } 
            a:hover,
			a:focus,
			a:active {
				text-decoration: underline;
            }
        }

        &.address { padding-bottom:40px; 
            &:before {
                background: url('../images/AboutUs/ContactUs/ic_contactus-06Address.png') left top no-repeat; background-size:100%; 
            }
        }
    }
}
