.CategoryGrid { position:relative; padding-top:60px; // OK Henry

    .Title { background-color:$corporateRedColor; 
        h2 { font-size:30px; color:$bodyBgColor; text-align:center; padding:10px 15px; margin:0px;
            &.left-align { text-align:left; }
            &.spacing { margin-bottom:20px; margin-top:30px; }
        }
    }
    .Contestor { background:$bodyBgColor; margin:0px; height:100%;
        h3 { color:$corporateRedColor; font-size:24px; text-align:center; padding:10px; margin:0px; }
        .img { line-height:400px; 
            img { padding:0px 30px; width:100%; vertical-align:middle;}
        }
        h5 { text-align:center; }
        h4 { text-align:center; font-size:24px; padding-top:20px; }
        p { padding:15px; }
    }

    .spacing20 { margin-bottom:10px; }
    .spacing40 { margin-bottom:40px; }
}
