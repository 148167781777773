html, 
body { font-family:$font-family-body; font-size: $global-font-size; font-weight:normal; color:$bodyColor; line-height: $global-line-height;
        margin:0; padding:0; border:0; vertical-align:baseline; height:100%; word-break:break-word; -webkit-text-size-adjust:100%; cursor: url('../images/schk_web-cursor.cur'), auto; }

body { -webkit-text-size-adjust:none; background:$bodyBgColor; overflow-y:scroll; opacity:1; 
	&.active{ opacity:1; }
    * { outline:none; box-sizing:border-box; }
}

@media print {
   body {
        -webkit-print-color-adjust: economy;
        -webkit-print-color-adjust: exact;
   }
}

a { margin:0; padding:0; border:0; text-decoration:none; color:$bodyColor; word-wrap:break-word; font-size:13px; cursor: url('../images/schk_web-cursor.cur'), auto;
    &:hover,
    &:active,
    &:focus { color:$aHoverColor; text-decoration:none !important; }
    img { display:block; border:none; }

    &:hover { cursor: url('../images/schk_web-cursor.cur'), auto; }
}

h1, 
h2, 
h3, 
h4, 
h5, 
h6 { display:block; margin:0; padding:0; font-weight:normal; color:$bodyColor; line-height:1.4em; }

h1 { font-size:48px; margin:0 0 20px 0; }
h2 { font-size:26px; margin:0 0 15px 0; }
h3 { font-size:20px; margin:0 0 30px 0; font-weight:bold; }
h4 { font-size:19px; margin:0 0 10px 0; font-weight:bold; }
h5 { font-size:16px; margin:0 0 10px 0; }
h6 { font-size:12px; margin:0 0 4px 0; }

p { line-height:1.6em; margin:0 0 20px 0; font-size:14px; 
    a { color:$aColor; text-decoration:none; font-size:15px; }
}

hr { height:1px; margin:0; padding:0; border:0; background:$majorLightGrey; }

ol { }
ul { }
dl, dt, dd { margin:0; padding:0; }

table { width:100%; border-collapse:collapse; border-spacing:0; }
tr { }
td { vertical-align:top; padding:2px 0; }

/* custom */
strong { font-weight:bold; }
em { font-style:italic; }

.left { float:left; }
.right { float:right; }

.hide { display: none !important; }
.show { display: block !important; }

.align-left { text-align:left; }
.align-center { text-align:center; }
.align-right { text-align:right; }

.align-top { vertical-align:top !important; }
.align-middle { vertical-align:middle !important; }
.align-bottom { vertical-align:bottom !important; }

.clearfix { zoom:1; 
    &:after	{ content:"."; display:block; clear:both; width:0px; height:0px; visibility:hidden; font-size:0; }
}

.color-white { color:#fff!important; }
