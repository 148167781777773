// //ELEMENTS
// input[type=text],
// input[type=email],
// input[type=password],
// textarea,
// select { width:100%; margin:0; padding:15px; border:solid 1px #cccccc; color:#444; background:#fff; font-family:$font-family-body; font-size:18px; border-radius:3px; -webkit-appearance:none; line-height:1.4em; }

// input[type=text],
// input[type=password],
// select { height:56px !important; }

// select { background:#fff url('../images/mobile/04_list_a_space/btn_arrow-form.png') center right 15px no-repeat;
//     option { color:#444; }
// }

// textarea { min-height:100px; }

// input[type=checkbox], 
// input[type=radio] { margin:0; }

// label { font-weight:normal; margin:0; padding:0; }

.button-wrapper { padding:20px;
    &.white { background-color:#fff; }
    &.lavender { background-color:$bgLavender; }
    .wrap {
        .button { width:100%; max-width:150px; height:60px; margin:0 0 10px 10px; border-radius:30px; display:inline-block; 
            &.print { background:url('../images/btn_print.png') center center no-repeat; background-size:100%;}
            &.facebookShare { background:url('../images/btn_share.png') center center no-repeat; background-size:100%; }
            &.download { background:url('../images/btn_download.png') center center no-repeat; background-size:100%; }
            &.copyLink { background:url('../images/btn_copylink.png') center center no-repeat; background-size:100%; }
        }
        .button2 { width:100%; max-width:150px; height:60px; margin:0 0 10px 10px; border-radius:30px; display:inline-block; 
            &.print { background:url('../images/btn_print_CH.png') center center no-repeat; background-size:100%;}
            &.facebookShare { background:url('../images/btn_share_CH.png') center center no-repeat; background-size:100%; }
            &.download { background:url('../images/btn_download_CH.png') center center no-repeat; background-size:100%; }
            &.copyLink { background:url('../images/btn_copylink_CH.png') center center no-repeat; background-size:100%; }
        }
        .wrapper-copyLink { width:100%; max-width:150px; height:60px; margin:0; border-radius:30px; display:inline-block; position:relative;
            .message { position:absolute; top:100%; text-align:center; width:100%; margin:0 0 0 10px; padding: 5px; color:$aColor; }
        }
    }
}

.button { width:100%; width:120px; height:60px; margin:0; border-radius:30px; display:inline-block; text-indent:-9999px;
    &.download { background:url('../images/btn_download.png') center center no-repeat; background-size:100%;}
}
.button2 { width:100%; width:120px; height:60px; margin:0; border-radius:30px; display:inline-block; text-indent:-9999px;
    &.download { background:url('../images/btn_download_CH.png') center center no-repeat; background-size:100%;}
}


.errorMessage { padding:5px 0; text-align:left; color:$corporateRedColor; }

.form-wrapper { padding:40px; text-align:center; font-size:20px; 
    h4 { text-align:left; color:$corporateRedColor; }
    .grid { padding:20px;
        input { padding:5px 10px; }
        button { width:100%; background-color:$corporateRedColor; color:#fff; font-size:20px; text-transform:none; border-radius:0; }
        a { text-align:center; font-size:20px; color:$corporateRedColor; }
    }
    ul { width:100% !important;
        li { text-align:left !important; padding:5px; color:$majorDarkGrey; width:100% !important; }
    }
}
