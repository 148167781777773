.quiz-container {
    .quiz {
        display: flex;
        flex-direction: column;

        .quiz-title,
        .quiz-footer,
        .quiz-content {
            padding-left: 20px;
            padding-right: 20px;
        }

        .quiz-title,
        .quiz-footer {
            flex: 0;
            background-color: #fff;
        }

        .quiz-title {
            h1 {
                font-size: 2rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
                color: $majorDarkBlue;
            }
        }

        .quiz-content {
            color:#fff;
        }

        .quiz-footer {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
    .quiz-question {
        .quiz-content {
            background-image: url('../images/ResourcesForYou/Quiz/bg_Quiz.png');
            background-size: cover;
            display: flex;
            flex-direction: column-reverse;
            padding-right: 100px;

            @media (min-width: $screen-sm-min) {
                flex-direction: row;
            }

            .question-text,
            .feedback-text {
                color: black;
                border-radius: 10px;
                padding: 1rem;
                line-height: 1.5rem;
            }

            .robot-logo {
                flex: 0;
            }

            .question-area {
                flex: 1;

                h3 {
                    font-weight: normal;
                    color: white;
                    display: block;
                    margin-top: 1rem;
                    margin-bottom: 1.5rem;
                }
                .question-text {
                    background-color: #B6E4FA;
                }
            }

            .quiz-answer-choices {
                margin-top: 1.5rem;
                display: flex;
                text-align: center;
            }

            .quiz-answer-choice {
                flex: 1;
            }

            .quiz-answer-feedback {
                padding-top: 1.5rem;
                padding-bottom: 1rem;
                position: relative;

                .feedback-text {
                    background-color: #E6E9EF;
                }

                .next-question-button-div {
                    position: absolute;
                    right: -60px;
                    bottom: 1rem;
                }

                .next-question-button {
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            .hide-quiz-answer-feedback {
                visibility: hidden;
            }
        }

        .quiz-footer {
            text-align: center;
        }
    }

    .quiz-result {
        .quiz-title {
            text-align: center;
        }

        .quiz-content {
            background-image: url('../images/ResourcesForYou/Quiz/bg_Quiz-Finish.png');
            background-size: cover;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (min-width: $screen-sm-min) {
                flex-direction: row;
            }

            .score {
                flex: 1;
                z-index: 10;
            }

            .score-circle {
                margin-left: auto;
                margin-right: 0;
                width: 30rem;
                height: 30rem;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                border-radius: 50%;
                border: medium solid $bgBlueGreen;
                background-color:$majorDarkBlue;

                .score-value {
                    font-size: 5rem;
                    font-weight: normal;
                    .score-number {
                        font-size: 10rem;
                        font-weight: bold;
                    }
                }
            }

            .score-breakdown {
                flex: 1;
                z-index: 5;
                margin-left: 5rem;

                li {
                    list-style-image: url('../images/ResourcesForYou/Quiz/box_Quiz-bullet-point.png');
                    line-height: 5rem;
                    font-size: 2rem;
                    font-weight: normal;
                    padding-left: 0.5rem;
                    b {
                        font-size: 4rem;
                        font-weight: bold;
                    }
                }
            }
        }

        .quiz-footer {
            button {
                background: transparent;
                border: none;
                cursor: url('../images/schk_web-cursor.cur'), auto;
                font-size: large;
                &:hover {
                    opacity: 0.7;
                }
            }

            img {
                margin-right: 1rem;
                cursor: url('../images/schk_web-cursor.cur'), auto;
            }
        }
    }

    input[type=radio] {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    label {
        background: url('../images/ResourcesForYou/Quiz/box_Quiz-Empty.png') center / contain no-repeat;
        display: inline-block;
        position: relative;
        padding: 1rem 2rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
        cursor: url('../images/schk_web-cursor.cur'), auto;

        &:hover {
            opacity: 0.7;
        }

        width: 10rem;
    }

    input[type=radio]:checked + label {
        background-image: url('../images/ResourcesForYou/Quiz/box_Quiz-Selected.png');
    }
}
