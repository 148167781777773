.GridGrid { // OK Henry
    &.userGuide {
        .lower { background:white}
    }

    .lower { padding:20px; background:$bgBlueGreen; 
        .grid { position:relative; background:#fff; height:100%;
            .top { position:relative; 
                img { width:100%; min-height:305px; max-height:305px; }
                .text { position:absolute; bottom:0px; background-color:$textBoxBgDark; width:100%; padding: 20px; 
                    &.light-background { background-color:$textBoxBg; }
                    a {color:#fff; font-size:20px; }
                }
            }
            .bottom { background:#fff; padding:20px; 
                h3 { font-size:16px; padding:0 0 10px 0; margin:0 0 10px 0; } 
                h4 { font-size:12px; margin:0px; };
                  .twoImageButton{justify-content: space-around; display: flex;
                    img { max-width: 30% }
            }
            }
        }
    }
}