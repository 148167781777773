.loginRegister { background:#fff; 
    .nav-tabs { border:none; 
        li { width:50%; text-align:center; 
            &.active{
                a { cursor:inherit; border:none; border-top:solid 8px $corporateRedColor; background:#fff; color:$corporateRedColor;  }
            }

            a { border-radius:0; border:none; background:$majorDarkGrey; color:#fff; cursor:inherit; margin:0; padding:12px 20px 20px; font-size:18px; border-top:solid 8px transparent; 
                &:focus { outline:none; }
            }
        }
    }
    .DisclaimerWrapper{
        padding:15px;
        border:solid 1px #000;
        // width: 100%
    }
}
