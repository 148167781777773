.sitemap {
    ul { list-style:none !important; display:block!important; margin:0 0 20px 0 !important; padding:0!important;
        li { list-style:none !important; margin: 0 0 10px 0 !important; padding:0 !important; 
            &:before { display:none!important; }
            a { font-size: 16px; display:block; margin:0 0 10px 0!important; padding:0; 
                &.large { font-size:24px; margin:0 0 20px 0!important; color:#000 !important;  
                    &:hover, &:active { color:#000 !important; }
                }
            }

            >ul { list-style:none !important; margin:0 0 0 20px!important; 
                >li {
                    &:before { display:inline-block!important; 
                    a {font-size: 16px; display:block; margin:0 0 10px 0!important; padding:0;}
                    }
                }
            }
        }
    }
}