.wrapper-heroMenu { 

    .heroMenu { position: relative; max-width: 1200px; margin: 0 auto; 
        .heroSlider { position:absolute; width:83%; bottom:25.7%; left:0; right:0; margin:0 auto; z-index:1;
            .slick-slider { 
                img { width:100%; }
            }
        }

        img { position:relative; width:100%; z-index:2; }

        .logo-NA-hero { width:28.3%; position:absolute; top:10px; left:20px; z-index:3;  
			a { display:block; 
				img { width:100%; }
			}
        }
        
        .viewMore { display:inline-block; position:absolute; bottom:30%; width:100%; left:0; right:0; background:url('../images/HeroMenu/img_homepage_arrow-viewmore.png') center bottom no-repeat; text-align:center; color:#F3FF00; font-size:25px; z-index:3; padding-bottom: 15px; text-shadow: 2px 2px 0px #000000;}

        .figure {
            display: block;
            position: absolute;
            z-index: 3;
            background: left bottom / contain no-repeat;
            &.about {
                background-image: url('../images/HeroMenu/img_homepage_menu_01-About_default.png');
                width: 14vw;
                height: 14vw;
                left: 8%;
                bottom: 3%;
                &.active {
                    background-image: url('../images/HeroMenu/img_homepage_menu_01-About_active.png');
                }
            }
            &.resource {
                background-image: url('../images/HeroMenu/img_homepage_menu_02-Resource_default.png');
                width: 13vw;
                height: 13vw;
                left: 21.00%;
                bottom: 1%;
                &.active {
                    background-image: url('../images/HeroMenu/img_homepage_menu_02-Resource_active.png');
                }
            }
            &.featured {
                background-image: url('../images/HeroMenu/img_homepage_menu_03-Featured_default.png');
                width: 14vw;
                height: 14vw;
                left: 36.5%;
                bottom: 0.5%;
                &.active {
                    background-image: url('../images/HeroMenu/img_homepage_menu_03-Featured_active.png');
                }
            }
            &.research {
                background-image: url('../images/HeroMenu/img_homepage_menu_04-Research_default.png');
                width: 13vw;
                height: 13vw;
                left: 53%;
                bottom: 1.5%;
                &.active {
                    background-image: url('../images/HeroMenu/img_homepage_menu_04-Research_active.png');
                }
            }
            &.event {
                background-image: url('../images/HeroMenu/img_homepage_menu_05-Ourevent_default.png');
                width: 13vw;
                height: 13vw;
                left: 66%;
                bottom: 0;
                &.active {
                    background-image: url('../images/HeroMenu/img_homepage_menu_05-Ourevent_active.png');
                }
            }
            &.external {
                background-image: url('../images/HeroMenu/img_homepage_menu_06-External_default.png');
                width: 10vw;
                height: 10vw;
                left: 83.5%;
                bottom: 1.5%;
                &.active {
                    background-image: url('../images/HeroMenu/img_homepage_menu_06-External_active.png');
                }
            }
        }
    }

    .cached-image {
        display: none;
    }
}
