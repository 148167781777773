// template bottoms
.upper {
    img { width:100%; }
    iframe { width:100%; border:none; min-height:480px; }

    &.lavender { background-color:$bgLavender;}
}

.bottom { padding:40px; 

    //added by Linus, used in external resource page
    h2 { color:$corporateRedColor; font-size:30px; margin:0 0 10px 0; display:inline-block}
    
    // page title, good
    h3 { color:$corporateRedColor; font-size:28px; margin:0 0 20px 0; padding:0 0 10px 0; display:inline-block; border-bottom: dotted 2px #000; }
    
    // Good
    h4 { color:$corporateRedColor; font-size:18px; margin:0 0 10px 0; }


    h5 { color:$majorDarkGrey; font-size:13px; margin:0 0 20px 0; }
    
    ul { list-style:none; position:relative; margin:0 0 0 -10px;
        li::before {content: ''; display: inline-block; width:6px; height:6px;border-radius:5px; background-color:$corporateRedColor; position:absolute; top:8px; left:-20px; text-align:center; vertical-align:middle;
        }
        li { padding-bottom:20px; position:relative;}
    }
    
    p { font-size:17px; margin:0 0 10px 0; 
        a { font-size:17px; }
    }

    img { width:100%; max-width:600px; margin:0 auto 20px; display:block; }

    table {
        tr {
            td { padding:30px;}
        }
    }

    table.border { border:solid 1px #000; margin:0 auto; 
        tr {
            td { padding:20px; vertical-align:middle; text-align:center; 
                &:first-child { text-align:left; }
            }
        }
    }

    video { max-width:100%; }

    // for mission page only
    &.mission {
        ul { 
            li {display:inline-block;}
        }
    }
}

//research finding
.researchFinding { padding:40px;
    //added by Linus, used in external resource page
    h2 { color:$corporateRedColor; font-size:30px; margin:0 0 10px 0; display:inline-block}
    
    // page title, good
    h3 { color:$corporateRedColor; font-size:28px; margin:0 0 20px 0; display:inline-block; border-bottom: dotted 2px #000; }
    
    // Good
    h4 { font-size:18px; margin:0 0 10px 0; }


    h5 { color:$majorDarkGrey; font-size:13px; margin:0 0 20px 0; }
    
    ul { list-style:none; position:relative; margin:0 0 0 -10px;
        li::before {content: "•"; color: $corporateRedColor; font-size:300%; display: inline-block; width:30px; height:30px; position:absolute; top:-3px; left:-30px; 
        }
        li { padding-bottom:20px; position:relative;}
    }
    
    p { font-size:17px; margin:0 0 10px 0; 
        a { font-size:17px; }
    }

    img { width:100%; max-width:600px; margin:0 auto 20px; display:block; }

    table {
        tr {
            td { padding:30px;}
        }
    }

    video { max-width:100%; }
}

@mixin horizontalHelpBoardContent {
    width: 400px;
    max-width: 95%;
    top: 0;
    left: auto;
    right: 10px;

    .wrapperText {
        padding-top: 0;

        .text {
            display: block;
            width: 100%;
            border: none;
            border-top: 2px dotted $majorDarkGrey;
            padding: 30px 10px;
        }
    }
}

// seek help
.seekHelp-Wrapper {
    position: absolute;
    top: 90px;
    width: 100%;
    max-width: 1200px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 103;

    &.smaller {
        max-width: 1640px;
        top: 180px;
        .seekHelp {
            top: 0;
            right:20px;
        }
    }

    .seekHelp {
        display: block;
        position: absolute;
        top: 0;
        right: 6%;
        width: 140.4px;
        height: 57.655px;
        background: url('../images/btn_Homepage-SeekHelp.png') center top / 100% no-repeat;
        z-index: 3;

        &:hover { background-position: center bottom; }
        span {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            padding: 20px 0;
            margin-left: 14%;
            font-size: 16px;
            color: #fff;
            text-align: center;
        }
        // &.no { display:none; }

        &.show { display:block; }
        &.hide { display:none; }
    }
    .wrapper-helpBoard { background:$majorLightGrey; position:absolute; height:auto; width:calc(100% - 80px); max-width:1120px; top:20px; left:0; right:0; margin:0 auto; padding:5px; z-index:3;
        
        .helpBoard { position:relative; height:auto; border:5px solid $corporateRedColor; 
            .icon { width:66px; height:66px; background:url('../images/btn-SeekHelp_right.png') center bottom no-repeat; background-size:100%; position:absolute; left:-45px; top:-20px;} 
            
            h3 { text-align:center; color:$corporateRedColor; margin:30px 0 0 0; }
        }

        .wrapperText {
            position:relative;
            display:table;
            padding:20px;

            .text {
                display: table-cell;
                padding: 10px 50px;
                border-left: 2px dotted $majorDarkGrey;
                width: 33%;
                vertical-align: middle;
                text-align: center;

                &:first-child {
                    border:none;
                }
            }
        }

        @media (max-width: ($screen-sm-min - 1)) {
            @include horizontalHelpBoardContent;
        }

        &.smaller {
            @include horizontalHelpBoardContent;
        }

        .show { display:block; }
        .hide { display:none; }
    }
}

@media (min-width: $screen-sm-min) {
    .seekHelp-Wrapper {
        z-index: 3;

        .seekHelp {
            width: 280.8px;
            height: 115.31px;

            span {
                padding: 48px 0;
                font-size: 20px;
            }
        }
    }
}
