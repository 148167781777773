.ImageGridParents {  // OK Henry
    .bottom { padding:20px; background:$corporateRedColor; 
        .grid { position:relative; 
            img { width:100%; margin:0; }
            .text { position:absolute; bottom:0px; background-color:$textBoxBgDark; width:100%; padding: 20px; 
                a { color:#fff; font-size:20px; }
            }
        }
    }
}

.ImageGrid {
    &.Primary { 
        ul { margin:0; 
            li {
                &.active { 
                    a {background:$bgGreenYellow; }
                }
                a { background:$bgGreenBlue; 
                    &:focus{ outline:none; }
                }
            }
        }
        .inner { background:$bgGreenYellow; }
    }
    &.Secondary { 
        ul { margin:0; 
            li {
                &.active { 
                    a {background:$bgGreenBlue; }
                }
                a { background:$bgGreenYellow; 
                    &:focus{ outline:none; }
                }
            }
        }
        .inner { background:$bgGreenBlue; }
    }

    .bottom { padding:0; 
        ul {
            li { width:50%; text-align:center; padding:0;
                &:before { display:none; }
                &.active {
                    a { border-top:8px solid $corporateRedColor; border-radius:0px; margin:0px; font-size:14px; padding-bottom:18px !important; border-left:0px !important; border-right:0px !important; }
                }

                a { border-top:8px solid transparent; border-radius:0px; margin:0px; font-size:14px; padding-bottom:18px !important; border-left:0px !important; border-right:0px !important;  }
            }
        }

        .inner { padding:20px; 
            .grid { position:relative; 
                img { width:100%; margin:0; }
                .text { position:absolute; bottom:0px; background-color:$textBoxBgDark; width:100%; padding: 20px; 
                    a {color:#fff; font-size:20px; }
                }
            }
        }
    }
}
