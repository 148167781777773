.pledge {
    
    h2 { margin:0;}

    .fill-wrapper { position:relative;
        .text { position:absolute; top:5%; left:10%; right:10%; bottom:0%; text-align:center;
            h3 { color :#fff; font-size:140%; font-weight:normal; margin:0 0 10px 0; }
            
            p { color:#fff; font-size:125%; font-weight:normal; margin:0 0 10px 0; line-height:1.4em; }
            
            .input-wrapper { margin:0 auto 10px; max-width:640px;
                input { width:100%; padding:15px; font-size:16px; }
            }
            .nextStep {
                width:50%; height:50%; margin:0 auto; display:inline-block; background:url('../images/ResourcesForYou/NetizenPledge/btn_netizenplledge-Next.png') center top no-repeat; background-size:20%; 
            }
            .tick { width:20%; height:30%; margin:0 auto; display:inline-block; background:url('../images/ResourcesForYou/NetizenPledge/btn_netizenplledge-Empty.png') center top no-repeat; background-size:100%; 
                &:hover,
                &.active {
                    background:url('../images/ResourcesForYou/NetizenPledge/btn_netizenplledge-Selected.png') center center no-repeat; background-size:100%; 
                }
            }
            .pledgeOverflow{
                max-height:60%;overflow: auto; margin-bottom: 5%;
            }
        }
    }

    .finish { display:none; 
        &.show { display:flex; }
    }

    .fill-wrapper-cert {
        h4 { color:#fff !important; font-size:30px!important; margin:0 0 20px 0; }
        h5 { color:#ffff88; font-size:22px !important; margin:10px 0 10px 0; line-height:1.4em; }
        p { color:#ffff88; font-size:14px !important; margin:0 0 10px 0;  }
        .more { margin: 0 0 20px 0; }
    }
}

@media(min-width: 800px){
.pledge {
    .fill-wrapper { position:relative;
        .text { position:absolute; top:15%; left:100px; right:100px; text-align:center;
            h3 { color :#fff; font-size:36px; font-weight:normal; margin:0 0 30px 0; }
            
            p { color:#fff; font-size:20px; font-weight:normal; margin:0 0 40px 0; line-height:1.4em; }
            
            .input-wrapper { margin:0 auto 50px; max-width:640px;
                input { width:100%; padding:15px; font-size:16px; }
            }
            .nextStep {
                width:70px; height:125px; margin:0 auto; display:inline-block; background:url('../images/ResourcesForYou/NetizenPledge/btn_netizenplledge-Next.png') center center no-repeat; background-size:100%; 
            }
            .tick { width:150px; height:150px; margin:0 auto; display:inline-block; background:url('../images/ResourcesForYou/NetizenPledge/btn_netizenplledge-Empty.png') center center no-repeat; background-size:100%; 
                &:hover,
                &.active {
                    background:url('../images/ResourcesForYou/NetizenPledge/btn_netizenplledge-Selected.png') center center no-repeat; background-size:100%; 
                }
            }
        }
    }

    .fill-wrapper-cert {
        h4 { color:#fff !important; font-size:40px!important; margin:0 0 20px 0; }
        h5 { color:#ffff88; font-size:32px !important; margin:20px 0 15px 0; line-height:1.4em; }
        p { color:#ffff88; font-size:24px !important; margin:0 0 10px 0;  }
        .more { margin: 0 0 20px 0; }
    }
}
}