.unity-game-container {
    position: relative;
    display: block;
}

.unity-game-div {

    img {
        display: block;
        position: absolute;
        height: 15%;
        right: -15%;
        bottom: 0px;
        padding-right: 10px;
        z-index: 1;
    }

    p {
        position: absolute;
        z-index: 1;
        color: red;
        justify-content: space-between;
        background: black;
        font-size: 3rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}

.unity-game-div.unity-game-div-fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: stretch;
    margin: 0;
    padding: 0;
    z-index: 11010;

    img {
        right: 5px;
        bottom: calc(50% - 25px);
        height: 50px;
        z-index: 11100;
    }
}
